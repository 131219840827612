
import { useEffect } from 'react';

import datacapture from '@ulta/core/utils/datacapture/datacapture';

import constants from '@ulta/utils/constants/constants';

import { useIntersectionObserver } from '../useIntersectionObserver/useIntersectionObserver';
import * as utils from './useIntersectionProcessor';

export const useIntersectionProcessor = ( props ) => {
  const { componentRef, root, rootMargin, threshold, dataCapture } = props;

  const { hasIntersected } = useIntersectionObserver( componentRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  } );

  useEffect( () => {
    if( !hasIntersected || !dataCapture ){
      return;
    }
    utils.processIntersectionData( { dataCapture } );
  }, [hasIntersected, dataCapture] );
};
export const processIntersectionData = ( data ) => {
  const { dataCapture } = data;
  if( !dataCapture ){
    return;
  }
  datacapture.processEvents( { dataCapture: { ...dataCapture } }, constants.DATACAPTURE_EVENT_TYPE.viewport );
};
